<template>
    <div class="card st-framework-card">

        <div class="card-header">
            <table style="border-collapse: collapse; width: 100%;">
                <tr>
                    <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                        <font-awesome-icon :icon="icon" size="sm"/>
                    </td>
                    <td style="text-align: center;">
                        {{ $i18n.tc('translations.' + name, 2) + " (" + (project ? project.label : $i18n.t('translations.No Project Found')) + ")" }}
                    </td>
                    <td style="width: 50px;"/>
                </tr>
            </table>
        </div>

        <div class="card-body p-0" v-if="pageLoaded">
            <Table :key="'component-table-'+tableReload" header-class="light" footer-class="light" body-class=""
                   with-filter with-pagination no-state-loading autofocus :lines-per-page=35
                   :rows="processedComponentList"
                   :fields="fields" :actions="actions" :state="state"
                   v-on:clear-selection="clearSelection"
                   v-on:inline-edit="inlineEdit"
                   v-on:refresh="getFaces"
                   v-on:select-all="selectAll"
                   v-on:select-component="selectComponent"/>
        </div>

    </div>
</template>

<script>
import Table from "@/components/Table";

/* global sketchup:false */

export default {
    name: 'Areas',
    components: {
        Table,
    },
    props: ['name', 'icon', 'api', 'state'],
    data() {
        return {
            pageLoaded: false,
            project: null,
            fields: null,
            actions: null,
            processedComponentList: [],
            tableReload: 0,
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        sketchup.getToolData();
    },
    methods: {
        clearSelection() {
            sketchup.clearSelection();
        },
        fetchData(projectId) {
            this.$http.get(this.api + '/manage_areas/' + projectId, {}).then((res) => {
                this.project = res.data.project;
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                sketchup.getFaces();
            }).catch((error) => {
                console.log("Areas:fetchData():error:", error);
            });
        },
        getFaces() {
            this.state.loading = true;
            sketchup.getFaces();
        },
        getFacesCallback(entities) {
            this.$worker.run((rawComponentList) => {
                let processedComponentList = [];
                const materialType = ['SOLID', 'TEXTURED', 'COLORIZED_TEXTURED'];

                rawComponentList.forEach(component => {
                    let i = processedComponentList.findIndex(item =>
                        (item.material_name === component.material_name)
                    );
                    if (i === -1) {
                        processedComponentList.push({
                            'entity_ids': [component.entity_id],
                            'material_type': (typeof materialType[component.material_type] === 'undefined' ? 'N/A' : materialType[component.material_type]),
                            'material_name': component.material_name,
                            'material_color': {
                                value: component.material_color,
                                label: component.material_name,
                            },
                            'quantity': 1,
                            'area': Math.round(10 * component.area) / 10,
                            'area_square_m': Math.round(100 * component.area / 1000000) / 100,
                        });
                    } else {
                        processedComponentList[i].entity_ids.push(component.entity_id);
                        processedComponentList[i].quantity++;
                        processedComponentList[i].area = Math.round(10 * (processedComponentList[i].area + component.area)) / 10;
                        processedComponentList[i].area_square_m = Math.round(100 * processedComponentList[i].area / 1000000) / 100;
                    }
                });
                processedComponentList.forEach(component => {
                    component.area_f = component.area.toFixed(1).replace('.', ',');
                    component.area_square_m_f = (Math.round(100 * component.area / 1000000) / 100).toFixed(2).replace('.', ',');
                });

                processedComponentList.sort(function (a, b) {
                    if (a.material_name.toLowerCase() < b.material_name.toLowerCase()) return -1;
                    if (a.material_name.toLowerCase() > b.material_name.toLowerCase()) return 1;
                    return 0;
                });

                return processedComponentList;
            }, [JSON.parse(entities)]).then(data => {
                this.processedComponentList = data;
                this.tableReload++;
                this.pageLoaded = true;
                this.state.loading = false;
            }).catch(error => {
                console.log("Areas:getFacesCallback():error:", error);
            });
        },
        getToolDataCallback(data) {
            this.fetchData(data.project_id);
        },
        inlineEdit(data) {
            let i = this.processedComponentList.findIndex(item =>
                (item.material_name === data.material_name)
            );
            this.processedComponentList[i].weight = (Math.round(100 * 7850 * this.processedComponentList[i].area_square_m * this.processedComponentList[i].thickness / 1000) / 100).toFixed(2).replace('.', ',');
            this.tableReload++;
        },
        selectAll() {
            sketchup.selectAll();
        },
        selectComponent(data) {
            sketchup.selectComponents(data.entity_ids);
        },
    }
}
</script>

<style scoped>
</style>
